import { PlusCircleIcon } from "@heroicons/react/24/outline";
import ModalCategory from "app/components/molecules/modals/modalCategory";
import SelectBasic from "app/components/molecules/selects/basic";
import SelectLoadMore from "app/components/molecules/selects/loadMore";
import SelectSearch from "app/components/molecules/selects/search";
import { i18n } from "app/i18n";
import { isArray, isObject } from "app/utils/validators/dataValidator";
import { useState } from "react";

export default function Input(props) {
  const { mode, value, options = [], placeholder = i18n("label.select_option"), hideSelection, onChange, multiple, creatable } = props;

  const [modal, setModal] = useState(false);
  const [createdOptions, setCreatedOptions] = useState([]);

  const createButton = () => {
    if (!creatable) return null;
    return (
      <button
        type="button"
        onClick={onOpenModal}
        className="relative z-10 flex items-center justify-center w-full text-center cursor-pointer font-semibold border-t py-3 px-4 text-gray-700 hover:bg-gray-100 transition-all ease-in-out duration-300"
      >
        <PlusCircleIcon className="h-5 w-5 min-w-[20px] mr-3" />
        <span className="uppercase text-xs"> {i18n("label.add_new_option")} </span>
      </button>
    );
  };
  const onOpenModal = () => {
    setModal({
      isOpen: true,
      onSave: (vals) => {
        // ADD OPTION
        let data = createdOptions || [];
        data.unshift({ value: `*${vals.title}`, label: vals.title });
        setCreatedOptions([...data]);
        // UPDATE VALUE
        let currentValue = value || [];
        if (multiple) onChange([...currentValue, data[0]]);
        else onChange(data[0]);
        setModal(false);
      },
      onClose: () => setModal(false),
    });
  };

  const getValue = () => {
    let opts = options?.length ? [...options] : [];
    if (createdOptions) opts = [...opts, ...createdOptions];

    // IF HAS VALUE
    if (value) {
      // IF MULTIPLE
      if (multiple) {
        // IF MULTIPLE AND VALUE IS OBJECT
        if (isObject(value)) {
          // PICK FROM OPTIONS
          if (opts?.length) return opts.filter((e) => value.value === e.value) || [];
          // PLACEHOLDER
          else return [value];
        }
        // IF MULTIPLE AND VALUE IS ARRAY
        else if (isArray(value, true)) {
          // PICK FROM OPTIONS
          if (opts?.length) return opts.filter((e) => value.find((i) => i.value === e.value));
          // PLACEHOLDER
          else return value;
        } else {
          return [];
        }
      }
      // IF NOT MULTIPLE
      else {
        // IF NOT MULTIPLE AND VALUE IS OBJECT
        if (isObject(value)) {
          // PICK FROM OPTIONS
          if (opts?.length) return opts.find((e) => value.value === e.value);
          // PLACEHOLDER
          else return value;
        }
        // IF NOT MULTIPLE AND VALUE IS ARRAY
        else if (isArray(value, true)) {
          // PICK FROM OPTIONS
          if (opts?.length) return opts.find((e) => value.find((i) => i.value === e.value));
          // PLACEHOLER
          else return value[0];
        }
      }
    } else {
      // SET DEFAULT VALUES
      if (multiple) return [];
      else return {};
    }
  };

  const getLabel = (noPlaceholder, basic) => {
    if (hideSelection) return `<span class='text-xs text-gray-400'>${placeholder || "&nbsp;"}</span>` || "&nbsp;";
    if (!value && noPlaceholder) return null;
    if (!value && basic) return `${placeholder || "&nbsp;"}`;
    if (!value || (!isArray(value, true) && !isObject(value, true))) return `<span class='text-xs text-gray-400'>${placeholder || "&nbsp;"}</span>` || "&nbsp;";
    if (!multiple) return value.label;
    else if (isObject(value)) return value.label;
    else return value.map((item) => item.label).join(", ");
  };

  const getOptions = () => {
    let temp = [];
    if (options?.length) temp = [...temp, ...options];
    if (createdOptions?.length) temp = [...temp, ...createdOptions];
    return temp;
  };

  return (
    <>
      {mode === "search" && <SelectSearch {...props} value={getValue()} label={getLabel(false, true)} options={getOptions()} createButton={createButton()} />}
      {mode === "normal" && <SelectBasic {...props} value={getValue()} label={getLabel()} options={getOptions()} createButton={createButton()} />}
      {mode === "loadmore" && <SelectLoadMore {...props} value={getValue()} label={getLabel()} options={getOptions()} createButton={createButton()} />}

      {creatable && <ModalCategory {...modal} />}
    </>
  );
}
