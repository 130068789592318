import { CheckBadgeIcon, LockClosedIcon } from "@heroicons/react/24/solid";
import Avatar from "app/components/atoms/avatar";
import Tooltip from "app/components/atoms/tooltip";
import Dropdown from "app/components/molecules/dropdown";
import Permission from "app/components/molecules/permission";
import { i18n } from "app/i18n";
import { compareRolePower, hasPermission } from "app/utils/roles";
import { useSelector } from "react-redux";

export default function ItemUser(props) {
  const { id, name, email, photo, role, projects, blocked, validated, project, onEdit, onDelete, onUnblock, onResend } = props;

  // STORE
  const { user: me } = useSelector((state) => state.auth);

  const projectId = !hasPermission(me, "DEVELOPER") ? me?.projects?.find((e) => e.project.id)?.project?.id : undefined;
  const myProjectRole = me?.role === "SUPER" ? me?.role : me?.projects?.find((e) => e.project.id === project)?.role;

  return (
    <tr key={id}>
      {/* MAIN */}
      <td className="whitespace-nowrap leading-10">
        <div className="flex items-center gap-4">
          <Avatar photo={photo} name={name} />
          <div className="flex flex-col items-start gap-0">
            <div className="leading-none flex items-center gap-1">
              <span className="leading-none">{name}</span>
              {project && validated && (
                <Tooltip message={i18n("label.validated")}>
                  <CheckBadgeIcon className="h-4 inline text-green-400" />
                </Tooltip>
              )}
            </div>
            {project && <span className="text-xs text-gray-300">{email}</span>}
          </div>
        </div>
      </td>
      {/* ACCOUNT */}
      {!project && (
        <td className="whitespace-nowrap">
          <div className="flex items-center gap-1">
            <span>{email}</span>
            {validated && (
              <Tooltip message={i18n("label.validated")}>
                <CheckBadgeIcon className="h-5 inline text-green-400" />
              </Tooltip>
            )}
            {blocked && (
              <Tooltip message={i18n("label.blocked")}>
                <LockClosedIcon className="h-4 inline text-red-800" />
              </Tooltip>
            )}
          </div>
        </td>
      )}
      {/* GLOBAL ROLE */}
      {!project && <td>{role && <kbd className="px-2 py-1.5 whitespace-nowrap text-xs font-semibold text-white bg-primary-500 rounded-lg">{role}</kbd>}</td>}
      {/* PROJECT ROLE */}
      {project ? (
        <td>
          {!!projects?.length &&
            projects
              .filter((e) => e.project.id === project)
              .map((item, index) => (
                <kbd key={String(index)} className="px-2 py-1.5 mr-1 whitespace-nowrap text-xs text-white bg-primary-500 rounded-lg lowercase">
                  {item.role}
                </kbd>
              ))}
        </td>
      ) : (
        <td>
          {!!projects?.length &&
            projects
              .filter((e) => (projectId ? e.project.id === projectId : true))
              .map((item, index) => (
                <kbd key={String(index)} className="px-2 py-1.5 mr-1 whitespace-nowrap text-xs text-white bg-primary-500 rounded-lg lowercase">
                  {!projectId && <strong className="uppercase text-sm">{item.project.name}</strong>} {item.role}
                </kbd>
              ))}
        </td>
      )}
      <td>
        {project ? (
          <Permission project min="ADMINISTRATOR">
            <Dropdown
              disabled={!blocked && validated && !compareRolePower(myProjectRole, role)}
              options={[
                { title: i18n("button.edit"), onClick: onEdit, icon: "edit", disabled: !compareRolePower(myProjectRole, role) },
                { title: i18n("button.resend_validation"), onClick: onResend, icon: "email", disabled: validated },
                { title: i18n("button.unblock"), onClick: onUnblock, icon: "unblock", disabled: !blocked },
                { title: i18n("button.remove_from_project"), onClick: onDelete, icon: "delete", delete: true, disabled: !compareRolePower(myProjectRole, role) },
              ]}
            />
          </Permission>
        ) : (
          <Permission min="ADMINISTRATOR">
            {me?.role !== "EXT_DEVELOPER" && (
              <Dropdown
                options={[
                  { title: i18n("button.edit"), onClick: onEdit, icon: "edit" },
                  { title: i18n("button.resend_validation"), onClick: onResend, icon: "email", disabled: validated },
                  { title: i18n("button.unblock"), onClick: onUnblock, icon: "unblock", disabled: !blocked || !hasPermission(me, "ADMINISTRATOR") },
                  { title: i18n("button.delete"), onClick: onDelete, icon: "delete", delete: true, disabled: !hasPermission(me, "ADMINISTRATOR") },
                ]}
              />
            )}
          </Permission>
        )}
      </td>
    </tr>
  );
}
