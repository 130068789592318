import { i18n } from "app/i18n";

let tabs = [
  {
    title: i18n("label.global"),
    key: "global",
  },
  {
    title: i18n("label.categories"),
    key: "categories",
  },
];

const components = [
  {
    key: "global",
    structure: [
      { type: "Select", title: i18n("label.author"), key: "author", options: [] },
      { type: "Multimedia", title: i18n("label.thumbnail"), key: "thumbnail", noAlt: true, clearMode: true },
      { type: "Textarea", title: i18n("label.excerpt"), key: "excerpt" },
    ],
  },
  {
    key: "categories",
    structure: [],
  },
];

export { tabs, components };
