import { i18n } from "app/i18n";
import validator from "validator";
import { isArray, isObject } from "./dataValidator";

export const validation = async (initialStructure, value, callback) => {
  let structure = await validateStructure(initialStructure, value);
  let validation = valid(structure);

  callback(structure, validation);
};

const validateStructure = async (schema, value) => {
  let structure = [];
  if (schema) structure = [...schema];
  for (let i = 0; i < structure.length; i++) {
    structure[i] = await validateComponent(structure[i], value);
  }
  return structure;
};

const validateList = async (schema, values) => {
  let errors = [];
  if (values && values.length) {
    for (let y = 0; y < values.length; y++) {
      const error = {};
      for (let i = 0; i < schema.length; i++) {
        const component = await validateComponent(schema[i], values[y]);
        if (component.error) error[component.key] = { error: component.error, errorMessage: component.errorMessage };
      }
      errors.push(error);
    }
  }
  return errors;
};

const validateComponent = async (element, value) => {
  let isInvalid = false;
  let component = { ...element, error: null };

  // VALIDATE IF HAS CONTENT
  if (component.isRequired) {
    let field_value = value && value[component.key] ? value[component.key] : "";
    let field_type = typeof field_value;

    // EXCLUSIVE STATEMENT
    if (element.type === "Toggle" || element.type === "Checkbox") {
      field_value = !!field_value;
      field_type = "boolean";
    }

    // CHECK IF STRING HAVE LENGTH
    if (field_type === "string") {
      if (validator.isEmpty(field_value)) {
        component.error = true;
        component.errorMessage = i18n("input.required_field");
      }
    } else if (field_type === "object") {
      // CHECK IF OBJECT HAVE KEYS
      if (isObject(field_value) && !isObject(field_value, true)) {
        component.error = true;
        component.errorMessage = i18n("input.required_field_object");
      }
      // CHECK IF ARRAY HAVE LENGTH
      else if (isArray(field_value) && !isArray(field_value, true)) {
        component.error = true;
        component.errorMessage = i18n("input.required_field_object");
      }
    } else if (field_type === "boolean") {
      if (!validator.isBoolean) {
        component.error = true;
        component.errorMessage = i18n("input.required_field");
      }
    } else if (field_type === "number") {
      if (!validator.isInt) {
        component.error = true;
        component.errorMessage = i18n("input.required_field");
      }
    } else {
      console.log("field_type", field_type);
    }

    if (component.error) isInvalid = true;
  }

  // VALIDATE VALUE LIMITS
  if (!isInvalid && (component.min || component.max)) {
    const field_value = value && value[component.key] ? value[component.key] : "";
    const isList = component.type === "List";
    const val = isList ? field_value.length : field_value;
    if (component.min && val < component.min) {
      component.error = true;
      component.errorMessage = i18n("input.lower_than_minimum");
    } else if (component.max && val > component.max) {
      component.error = true;
      component.errorMessage = i18n("input.bigger_than_maximum");
    }
    if (component.error) isInvalid = true;
  }

  // VALIDATE INPUT LENGTH
  if (!isInvalid) {
    // VALIDATE ARRAY LENGTH
    // TO-DO
    // INPUT TEXT CHARACTERS LENGTH
    // TO-DO
  }

  // VALIDATE RULES
  if (!isInvalid && component.rules) {
    for (let i = 0; i < component.rules.length; i++) {
      const rule = component.rules[i];
      if (!isInvalid) {
        const field_value = value && value[component.key] ? value[component.key] : "";
        if ((await rule.method(field_value, rule.args, value)) !== rule.validWhen) {
          component.error = true;
          component.errorMessage = rule.message;
          if (rule.code) component.code = rule.code;
        }
      }
    }
    if (component.error) isInvalid = true;
  }

  // VALIDATE GROUPS AND LISTS
  if (!isInvalid) {
    if (component.type === "Group") {
      const useComponents = component?.components?.length;
      const useChildren = component?.children?.length;
      if (useComponents || useChildren) {
        // REESTRUTURATE CHILDRENS
        if (useChildren) component.children = await validateStructure(component.children, value ? value[component.key] : null);
        if (useComponents) component.components = await validateStructure(component.components, value ? value[component.key] : null);
        component.error = !!component?.components?.find((item) => item.error) || !!component?.children?.find((item) => item.error);
        component.errorMessage = i18n("input.children_with_error");
      }
    } else if (component.type === "List") {
      const useComponents = component?.components?.length;
      const useChildren = component?.children?.length;
      if (useComponents || useChildren) {
        // REESTRUTURATE CHILDRENS
        if (useChildren) component.errorList = await validateList(component.children, value ? value[component.key] : null);
        if (useComponents) component.errorList = await validateList(component.components, value ? value[component.key] : null);
        component.error = !!component?.errorList?.find((item) => Object.keys(item).length);
        component.errorMessage = i18n("input.children_with_error");
      }
    } else if (component?.structure?.length) {
      component.structure = await validateStructure(component.structure, value || null);
      component.error = !!component?.structure?.find((item) => item.error);
      component.errorMessage = i18n("input.children_with_error");
    }
  }

  return component;
};

const valid = (structure) => {
  const validation = {};
  if (structure)
    structure.forEach((component) => {
      validation[component.key] = {};
      validation[component.key].isInvalid = !!component.error;
      validation[component.key].message = component.errorMessage;
      if (component.code) validation[component.key].code = component.code;
    });
  const isValid = !Object.keys(validation).filter((key) => validation[key].isInvalid).length;
  return { isValid, ...validation };
};
