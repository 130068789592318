import { isArray } from "../validators/dataValidator";

export const roles = {
  SUPER: 5,
  DEVELOPER: 4,
  EXT_DEVELOPER: 3,
  ADMINISTRATOR: 2,
  USER: 1,
};
export const projectRoles = {
  ADMINISTRATOR: 4,
  MANAGER: 3,
  EDITOR: 2,
  CONTRIBUTOR: 1,
};
export const modesList = [
  { value: "DEVELOPMENT", label: "Development" },
  { value: "STAGING", label: "Staging" },
  { value: "PRODUCTION", label: "Production" },
];
export const rolesList = [
  { value: "SUPER", label: "Super" },
  { value: "DEVELOPER", label: "Developer" },
  { value: "EXT_DEVELOPER", label: "External Developer" },
  { value: "ADMINISTRATOR", label: "Administrator" },
  { value: "USER", label: "User" },
];
export const projectRolesList = [
  { value: "ADMINISTRATOR", label: "Administrator" },
  { value: "MANAGER", label: "Manager" },
  { value: "EDITOR", label: "Editor" },
  { value: "CONTRIBUTOR", label: "Contributor" },
];
export const postTypeRolesList = [
  { value: "BLOCKED", label: "Blocked" },
  { value: "VIEWER", label: "Viewer" },
  { value: "EDITOR", label: "Editor" },
  { value: "CREATOR", label: "Creator" },
];

export const hasPermission = (user, role) => {
  if (!user || !role) return false;
  // SUPER CAN ALL
  if (user.role === "SUPER") return true;
  // IF GROUP OF ROLES
  if (isArray(role)) return role.includes(user.role);
  // ELSE
  return roles[user.role] >= roles[role];
};

export const hasPermissionProject = (user, project, role, dev) => {
  if (!project || !role || !user) return false;

  // SUPER CAN ALL
  if (user.role === "SUPER") return true;

  // IF DEVELOPER
  if (dev && user.role === "DEVELOPER") return true;

  // GET ROLE IN PROJECT
  const userProjectRole = user.projects.find((e) => e.project.id === project.id);
  if (!userProjectRole && !dev) return false;

  // VALIDATION
  return (projectRoles[userProjectRole.role] || 0) >= projectRoles[role];
};

export const compareRolePower = (role1, role2) => {
  if (!role1 || !role2) return false;
  // SUPER CAN ALL
  if (role1 === "SUPER") return true;
  // ELSE
  return roles[role1] > roles[role2];
};

export const compareProjectRolePower = (role1, role2) => {
  if (!role1 || !role2) return false;
  //
  return roles[role1] > roles[role2];
};
