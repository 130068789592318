import Dropdown from "app/components/molecules/dropdown";
import LabelSyncStatus from "app/components/molecules/labels/labelSyncStatus";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import ModalContentType from "app/components/molecules/modals/modalContentType";
import BuilderConstructor from "app/components/organisms/builder";
import { useDeleteContentTypeMutation, useGetContentTypeQuery, useUpdateContentTypeMutation, useUpdateContentTypeStructureMutation } from "app/stores/builder";
import { showServerError, showServerSuccess } from "app/utils/server";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "app/components/atoms/loading";
import { i18n } from "app/i18n";

export default function ContentTypeEdit(props) {
  const { id, reloadList } = props;

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [modal, setModal] = useState(null);
  const [confirmModal, setConfirmModal] = useState(null);
  const [updateContentType, { isLoading: isUpdating }] = useUpdateContentTypeMutation();
  const [updateStructure, { isLoading: isUpdatingStructure, isError }] = useUpdateContentTypeStructureMutation();
  const [deleteContentType, { isLoading: isDeleting }] = useDeleteContentTypeMutation();
  const { data, isFetching, isLoading: isGetting, refetch } = useGetContentTypeQuery({ id });

  const isLoading = isDeleting || isUpdating || isGetting || isUpdatingStructure;

  useEffect(() => {
    refetch();
  }, [pathname]);

  const onUpdate = (structure) => {
    updateStructure({ id: data.id, structure }).then((res) => {
      if (res && res.data) refetch();
      else showServerError(res);
    });
  };

  const onEdit = () => {
    setModal({
      isOpen: true,
      contentType: data,
      buttonText: i18n("button.update_contentType"),
      onSubmit: (values) => {
        updateContentType({ ...values, id: data.id }).then((res) => {
          if (res && res.data) {
            refetch();
            reloadList();
            showServerSuccess(i18n("toast.success_changes_update"));
          } else {
            showServerError(res);
          }
          setModal(null);
        });
      },
      onClose: () => setModal(null),
    });
  };

  const onDelete = () => {
    setConfirmModal({
      isOpen: true,
      type: "DELETE",
      buttonText: i18n("button.delete"),
      title: i18n("label.delete_contentType"),
      text: i18n("alert.contentType_delete"),
      onConfirm: () => {
        deleteContentType({ id: data.id }).then((res) => {
          if (res && res.data) {
            reloadList();
            navigate("/dashboard/builders/content-types");
          } else showServerError(res);
        });
      },
      onClose: () => setConfirmModal(null),
    });
  };

  if (!id)
    return (
      <div>
        <p>{i18n("contentTypes.noItems")}</p>
      </div>
    );

  if (isFetching) return <Loading active className="h-full flex items-center justify-center" />;

  return (
    <>
      <BuilderConstructor data={{ ...data }} isLoading={isLoading} isError={isError} onUpdate={onUpdate}>
        <Dropdown
          options={[
            { title: i18n("button.edit"), onClick: onEdit, icon: "edit" },
            { title: i18n("button.delete"), onClick: onDelete, icon: "delete", delete: true },
          ]}
        />
      </BuilderConstructor>

      <ModalContentType {...modal} />
      <ModalConfirm {...confirmModal} />
    </>
  );
}
