import { gql } from "@apollo/client";
import { createSlice } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { serverAuth } from "../../utils/server";
import { setAuthUser } from "../auth";
import { refreshListUsers } from "app/utils/visual";

const initialState = {
  user: null,
  users: null,
  token: null,

  take: 16,
  page: 1,
  where: undefined,
  skip: undefined,
  orderBy: { name: "asc" },
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    setTake: (state, { payload }) => {
      state.take = payload;
    },
    setUsers: (state, { payload }) => {
      state.users = payload;
    },
    setWhere: (state, { payload }) => {
      state.where = payload;
    },
    setOrderBy: (state, { payload }) => {
      state.orderBy = payload;
    },
    setPage: (state, { payload }) => {
      state.page = payload;
      state.skip = (state.page - 1) * state.take;
    },
  },
});

export const usersApi = createApi({
  reducerPath: "userAPI",
  baseQuery: graphqlRequestBaseQuery(serverAuth),
  endpoints: (builder) => ({
    getMe: builder.query({
      query: () => ({
        document: gql`
          query {
            me {
              error
              user {
                id
                name
                role
                email
                photo {
                  id
                  path
                }
                projects {
                  role
                  project {
                    id
                    name
                  }
                }
              }
            }
          }
        `,
      }),
      transformResponse: (response) => response.me,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setAuthUser(data.user));
        } catch (error) {}
      },
    }),
    getUser: builder.query({
      query: ({ name, email, password }) => ({
        document: gql`
          query user($name: String!, $email: String!, $password: String!) {
            user(name: $name, email: $email, password: $password) {
              id
              name
            }
          }
        `,
        variables: {
          name,
          email,
          password,
        },
      }),
      transformResponse: (response) => response.user,
    }),
    getUsers: builder.query({
      query: ({ take, skip, where, orderBy }) => ({
        document: gql`
          query users($take: Int, $skip: Int, $where: UserWhere, $orderBy: UserOrderBy) {
            users(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
              id
              name
              email
              role
              blocked
              validated
              hasData
              photo {
                path
              }
              projects {
                role
                project {
                  id
                  name
                }
              }
              posttypes {
                role
                typeKey
              }
            }
          }
        `,
        variables: {
          take,
          skip,
          where,
          orderBy,
        },
      }),
      transformResponse: (response) => response.users,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (!args.noAttach) dispatch(setUsers(data));
        } catch (error) {}
      },
    }),
    createUser: builder.mutation({
      query: ({ name, email, role, projects, reuse }) => ({
        document: gql`
          mutation createUser($name: String!, $email: String!, $role: Role!, $projects: [ManagerUser!], $reuse: Boolean) {
            createUser(name: $name, email: $email, role: $role, projects: $projects, reuse: $reuse) {
              id
              name
              email
              role
            }
          }
        `,
        variables: {
          name,
          role,
          email,
          reuse,
          projects,
        },
      }),
    }),
    updateUser: builder.mutation({
      query: ({ id, name, email, role, projects, posttypes, inside }) => ({
        document: gql`
          mutation updateUser($id: String!, $name: String!, $email: String!, $role: Role!, $projects: [ManagerUser!], $posttypes: [ManageUserPostType!], $inside: String) {
            updateUser(id: $id, name: $name, email: $email, role: $role, projects: $projects, posttypes: $posttypes, inside: $inside) {
              id
              name
              email
            }
          }
        `,
        variables: { id, name, email, role, projects, posttypes, inside: inside },
      }),
    }),
    updateMe: builder.mutation({
      query: ({ id, name, photo, password, newPassword }) => ({
        document: gql`
          mutation updateMe($id: String!, $name: String!, $photo: GenericConnect, $password: String, $newPassword: String) {
            updateMe(id: $id, name: $name, photo: $photo, password: $password, newPassword: $newPassword) {
              id
              name
              role
              email
              photo {
                id
                path
              }
              projects {
                role
                project {
                  id
                }
              }
            }
          }
        `,
        variables: { id, name, photo: photo ? { connect: { id: photo.id } } : undefined, password, newPassword },
      }),
      transformResponse: (response) => response.updateMe,
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          const { user } = getState();

          // UPDATE AUTH USER
          dispatch(setAuthUser(data));

          // UPDATE LIST OF USERS
          refreshListUsers(user.users, dispatch, usersSlice, data);
        } catch (error) {
          console.log(error);
        }
      },
    }),
    deleteUser: builder.mutation({
      query: ({ id, user, inside, permanently }) => ({
        document: gql`
          mutation deleteUser($id: String!, $permanently: Boolean!, $user: String, $inside: String) {
            deleteUser(id: $id, permanently: $permanently, user: $user, inside: $inside) {
              id
            }
          }
        `,
        variables: { id, user, inside, permanently },
      }),
    }),
    restoreUser: builder.mutation({
      query: ({ id, alert }) => ({
        document: gql`
          mutation restoreUser($id: String!, $alert: Boolean!) {
            restoreUser(id: $id, alert: $alert) {
              id
            }
          }
        `,
        variables: { id, alert: !!alert },
      }),
    }),
    getTotal: builder.query({
      query: ({ where }) => ({
        document: gql`
          query usersTotal($where: UserWhere) {
            usersTotal(where: $where)
          }
        `,
        variables: {
          where,
        },
      }),
      transformResponse: (response) => response.usersTotal,
    }),
    isEmailAvailable: builder.query({
      query: ({ id, email }) => ({
        document: gql`
          query userEmailAvailable($id: String, $email: String!) {
            userEmailAvailable(id: $id, email: $email)
          }
        `,
        variables: {
          id,
          email,
        },
      }),
      transformResponse: (response) => response.userEmailAvailable,
    }),
    resendValidationEmail: builder.mutation({
      query: ({ id, email }) => ({
        document: gql`
          mutation resendValidationEmail($email: String!, $id: String) {
            resendValidationEmail(id: $id, email: $email)
          }
        `,
        variables: {
          id,
          email,
        },
      }),
      transformResponse: (response) => response.resendValidationEmail,
    }),
    unblockingUser: builder.mutation({
      query: ({ id }) => ({
        document: gql`
          mutation unblockUser($id: String) {
            unblockUser(id: $id)
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (response) => response.unblockUser,
    }),
    resendTokenCode: builder.mutation({
      query: ({ id, email }) => ({
        document: gql`
          mutation resendTokenCode($email: String!) {
            resendTokenCode(email: $email)
          }
        `,
        variables: {
          id,
          email,
        },
      }),
      transformResponse: (response) => ({ result: response.resendTokenCode }),
    }),
  }),
});

export const { setToken, setTake, setUsers, setWhere, setOrderBy, setPage } = usersSlice.actions;

export const {
  useGetMeQuery,
  useGetUserQuery,
  useIsEmailAvailableQuery,
  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useUpdateMeMutation,
  useGetTotalQuery,
  useLazyGetTotalQuery,
  useResendValidationEmailMutation,
  useDeleteUserMutation,
  useRestoreUserMutation,
  useUnblockingUserMutation,
  useResendTokenCodeMutation,
} = usersApi;

export default usersSlice.reducer;
