import { hasPermission } from "app/utils/roles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { i18n } from "app/i18n";

// React Router
import { Link, useLocation, Outlet, Navigate } from "react-router-dom";
import Select from "app/components/atoms/inputs/select/input";

// REQUESTS
import { setBuilderProject, useGetCustomInputsQuery } from "app/stores/builder";
import { useGetProjectsQuery } from "app/stores/projects";

const tabs = [
  { name: "sections.title", href: "/dashboard/builders/sections" },
  { name: "templates.title", href: "/dashboard/builders/templates" },
  { name: "postTypes.title", href: "/dashboard/builders/post-types" },
  { name: "contentTypes.title", href: "/dashboard/builders/content-types" },
];

export default function Builders() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  // STORE
  const { user } = useSelector((state) => state.auth);
  const { project } = useSelector((state) => state.builder);

  // REQUESTS
  const { data: projects } = useGetProjectsQuery({ orderBy: { name: "desc" } }, { skip: !hasPermission(user, "DEVELOPER") });

  const tabNormal = "border-white text-text hover:text-primary-500";
  const tabActive = "border-primary-500 text-primary-500";

  useEffect(() => {
    if (user?.projects?.length) {
      if (user?.projects?.length) {
        const projectIds = user.projects.map((e) => e.project);
        if (projectIds[0])
          dispatch(
            setBuilderProject({
              value: projectIds[0].id,
              label: projectIds[0].name,
            })
          );
      }
    }
  }, [user, dispatch]);

  const isCurrent = (index) => {
    return pathname.includes(tabs[index].href);
  };

  function onChangeProject(item) {
    dispatch(setBuilderProject(item));
  }

  const { isLoading } = useGetCustomInputsQuery();

  if (!user) return null;
  if (!hasPermission(user, "EXT_DEVELOPER")) return <Navigate to="/dashboard" />;

  return (
    <div className="h-full flex flex-col pt-36 lg:pt-20">
      <div className="absolute top-36 lg:top-20 left-0 right-0 flex items-center space-between h-20 px-6 bg-white border-b border-b-primary-50">
        <div className="w-48 max-w-[192px]">
          {hasPermission(user, "DEVELOPER") ? (
            <>{projects?.length && <Select value={project} options={projects?.map((item) => ({ value: item.id, label: item.name }))} onChange={onChangeProject} mode="normal" />}</>
          ) : (
            <>
              {user?.projects?.length > 1 && (
                <Select value={project} options={user?.projects?.map(({ project }) => ({ value: project.id, label: project.name }))} onChange={onChangeProject} mode="normal" />
              )}
            </>
          )}
        </div>
        <div className="flex flex-1 justify-center">
          <nav className="flex space-x-4" aria-label="Tabs" disabled={isLoading}>
            {tabs.map((tab, index) => (
              <Link
                to={tab.href}
                key={tab.name}
                aria-current={isCurrent(index) ? "page" : undefined}
                className={`border px-6 py-3 font-medium text-sm rounded-md main-transition ${isCurrent(index) ? tabActive : tabNormal}`}
              >
                {i18n(tab.name)}
              </Link>
            ))}
          </nav>
        </div>
        <div className="w-48" />
      </div>
      <Outlet />
    </div>
  );
}
