import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Molecules
import DashboardNavbar from "app/components/molecules/navbars/dashboard";

// Pages
import Api from "../Api";
import Users from "../Users";
import Projects from "../Projects";
import Settings from "../Settings";
import Builders from "../Builders";

// Builders inner pages
import Sections from "../Builders/Sections";
import Templates from "../Builders/Templates";
import PostTypes from "../Builders/PostTypes";
import ContentTypes from "../Builders/ContentTypes";
import { setProject } from "app/stores/project";

export default function Dashboard() {
  // PARAMS
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  // STORE
  const { project } = useSelector((state) => state.project);

  useEffect(() => {
    if (project) dispatch(setProject(null));
  }, [project, dispatch]);

  const onChangeLanguage = (locale) => {
    i18n.changeLanguage(locale);
  };

  return (
    <div className="max-h-screen h-screen overflow-auto flex flex-col pt-20">
      <DashboardNavbar toggleLanguage={onChangeLanguage} />
      <main className="min-h-full">
        <Routes>
          <Route index element={<Projects />} />
          <Route path="api" element={<Api />} />
          <Route path="users" element={<Users />} />
          <Route path="settings" element={<Settings />} />
          <Route path="builders/*" element={<Builders />}>
            <Route path="sections">
              <Route path=":id" element={<Sections />} />
              <Route index element={<Sections />} />
            </Route>
            <Route path="templates">
              <Route path=":id" element={<Templates />} />
              <Route index element={<Templates />} />
            </Route>
            <Route path="post-types">
              <Route path=":id" element={<PostTypes />} />
              <Route index element={<PostTypes />} />
            </Route>
            <Route path="content-types">
              <Route path=":id" element={<ContentTypes />} />
              <Route index element={<ContentTypes />} />
            </Route>
            <Route path="*" element={<Navigate to="sections" />} />
          </Route>
        </Routes>
        {project?.id}
      </main>

      <ToastContainer position="bottom-right" />
    </div>
  );
}
