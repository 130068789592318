import Icon from "app/components/atoms/icons/icon";
import Loading from "app/components/atoms/loading";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import ModalUser from "app/components/molecules/modals/modalUser";
import Permission from "app/components/molecules/permission";
import ListTable from "app/components/molecules/table";
import { i18n } from "app/i18n";
import {
  setOrderBy,
  setPage,
  setWhere,
  useCreateUserMutation,
  useDeleteUserMutation,
  useGetTotalQuery,
  useGetUsersQuery,
  useResendValidationEmailMutation,
  useUnblockingUserMutation,
  useUpdateUserMutation,
} from "app/stores/users";
import { showServerError, showServerSuccess } from "app/utils/server";
import customValidator from "app/utils/validators/customValidator";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListUsersArchived from "./archived";
import { headers } from "./constants";
import ItemUser from "./itemUser";
import { hasPermission } from "app/utils/roles";

export default function ListUsers() {
  // ROUTE
  const dispatch = useDispatch();

  // STATE
  const [modal, setModal] = useState(null);
  const [confirmModal, setConfirmModal] = useState(null);
  const [reloadDeleted, setReloadDeleted] = useState(false);

  // STORE
  const { user } = useSelector((state) => state.auth);
  const { users, take, skip, where, orderBy } = useSelector((state) => state.user);

  // QUERIES
  const { data: total, refetch: updateTotal } = useGetTotalQuery({ where });
  const { isFetching, isLoading: isGetting, refetch: updateList } = useGetUsersQuery({ take, skip, where, orderBy }, { refetchOnMountOrArgChange: true });

  // MUTATIONS
  const [createUser, { isLoading: isCreating }] = useCreateUserMutation();
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();
  const [deleteUser, { isLoading: isDeleting }] = useDeleteUserMutation();
  const [unblockUser, { isLoading: isUnbloking }] = useUnblockingUserMutation();
  const [resendEmail, { isLoading: isSending }] = useResendValidationEmailMutation();

  // VARS
  const isLoading = isCreating || isUpdating || isFetching || isGetting || isDeleting || isSending || isUnbloking;

  useEffect(() => {
    if (user && !hasPermission(user, "DEVELOPER")) {
      const projectIds = user?.projects?.map((e) => ({ id: e.project.id }));
      dispatch(setWhere({ projects: { some: { project: { OR: projectIds } } } }));
    }

    return () => {
      dispatch(setWhere(undefined));
    };
  }, [user, dispatch]);

  // FUNCTION TO CREATE USER
  const onCreate = () => {
    setModal({
      isOpen: true,
      onClose: () => setModal(null),
      onSubmit: (data) => {
        createUser(data).then((res) => {
          if (res && res.data) {
            updateList();
            updateTotal();
            setModal(null);
            showServerSuccess(i18n("toast.success_add"));
          } else {
            showServerError(res);
          }
        });
      },
    });
  };
  // FUNCTION TO UPDATE USER
  const onEdit = (user) => {
    setModal({
      user,
      isOpen: true,
      buttonText: i18n("button.update"),
      onSubmit: (data) => {
        updateUser({ ...data, id: user.id }).then((res) => {
          if (res.data) {
            updateList();
            setModal(null);
            showServerSuccess(i18n("toast.success_update"));
          }
          showServerError(res);
        });
      },
      onClose: () => setModal(null),
    });
  };
  // FUNCTION TO DELETE USER
  const onDelete = (user) => {
    let title = i18n("label.deactivate_account");
    let text = i18n("alert.delete_account");
    let inputs = [{ type: "Toggle", title: i18n("button.delete_permanentely"), key: "permanently", texts: [i18n("label.yes"), i18n("label.no")] }];

    if (user?.hasData) {
      title = i18n("alert.delete_user_with_transfer");
      text = i18n("alert.delete_account_with_transfer");
      inputs = [
        ...inputs,
        {
          key: "newUser",
          type: "Select",
          title: "Select User",
          placement: "top",
          options: users.filter((e) => e.id !== user.id).map((user) => ({ value: user.id, label: user.name })),
          rules: [
            {
              method: customValidator.isObjectEmpty,
              validWhen: false,
              message: i18n("input.required_field"),
            },
          ],
        },
      ];
    }

    setConfirmModal({
      text,
      title,
      inputs,
      isOpen: true,
      type: "DELETE",
      mode: "INPUTS",
      buttonText: i18n("button.delete"),
      onConfirm: (data) => {
        deleteUser({ id: user.id, permanently: !!data?.permanently, user: data?.newUser?.value || undefined, inside: undefined }).then((res) => {
          if (res?.data) {
            updateList();
            updateTotal();
            showServerSuccess(i18n("toast.success_delete"));

            // FORCE DELETE MODAL
            if (!data?.permanently) {
              setReloadDeleted(true);
              setTimeout(() => setReloadDeleted(true), 200);
            }
          } else {
            showServerError(res);
          }
          setConfirmModal(null);
        });
      },
      onClose: () => setConfirmModal(null),
    });
  };
  // FUNCTION TO RESEND TOKEN VALIDATION
  const onResend = (user) => {
    setConfirmModal({
      isOpen: true,
      mode: "BASIC",
      type: "EMAIL",
      title: i18n("label.resend_email"),
      buttonText: i18n("button.send_email"),
      text: i18n("label.resend_email_description"),
      onConfirm: () => {
        resendEmail({ id: user.id, email: user.email }).then((res) => {
          if (res && res.data) {
            showServerError(i18n("toast.success_email_resent"));
          } else {
            showServerError(res);
          }
          setConfirmModal(null);
        });
      },
      onClose: () => setConfirmModal(null),
    });
  };
  // FUNCTION TO UNBLOCK USER
  const onUnblock = (user) => {
    setConfirmModal({
      isOpen: true,
      mode: "BASIC",
      type: "CONFIRM",
      title: "Blocked User",
      buttonText: "Unblock",
      text: "This blocked user will come to login in platform.",
      onConfirm: () => {
        unblockUser({ id: user.id }).then((res) => {
          if (res && res.data) {
            showServerSuccess(i18n("toast.success_user_unblock"));
            updateList();
          } else {
            showServerError(res);
          }
          setConfirmModal(null);
        });
      },
      onClose: () => setConfirmModal(null),
    });
  };

  return (
    <div className="custom-container">
      <div className="flex items-center justify-between pt-32 lg:pt-16 pb-10">
        <span className="flex items-center">
          <h1 className="text_3xl">{i18n("label.users")}</h1>
          <Loading active={isLoading} className="ml-3" />
        </span>
        <div className="flex items-center gap-2">
          <Permission equal="ADMINISTRATOR">
            <button type="button" className="btn-primary-outline" onClick={onCreate}>
              <Icon name="plus" />
              {i18n("button.new_user")}
            </button>
          </Permission>
          <ListUsersArchived
            reload={reloadDeleted}
            onRestoreOne={() => {
              updateList();
              updateTotal();
            }}
          />
        </div>
      </div>
      <div>
        <ListTable headers={headers} setOrder={setOrderBy} setPage={setPage} store="user" total={total}>
          {!!users?.length &&
            users.map((user) => <ItemUser {...user} key={user.id} onEdit={() => onEdit(user)} onDelete={() => onDelete(user)} onResend={() => onResend(user)} onUnblock={() => onUnblock(user)} />)}
        </ListTable>
      </div>

      <ModalUser {...modal} isLoading={isLoading} />
      <ModalConfirm {...confirmModal} isLoading={isLoading} />
    </div>
  );
}
