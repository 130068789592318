import IconPlus from "app/components/atoms/icons/iconPlus";
import Loading from "app/components/atoms/loading";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import ModalTranslation from "app/components/molecules/modals/modalTranslation";
import Permission from "app/components/molecules/permission";
import Thead from "app/components/molecules/table/thead";
import { i18n } from "app/i18n";
import {
  setPage,
  setWhere,
  useCreateTranslationMutation,
  useDeleteTranslationMutation,
  useGetTranslationsQuery,
  useGetTranslationsTotalQuery,
  useUpdateTranslationMutation,
} from "app/stores/translations";
import { showServerError, showServerSuccess } from "app/utils/server";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ItemTranslation from "./itemTranslation";

export default function ListTranslations() {
  // PARAMS
  const dispatch = useDispatch();

  // STORE
  const { project, languages } = useSelector((store) => store.project);
  const { translations, total, page, take, skip, where, orderBy } = useSelector((store) => store.translations);

  // STATE
  const [modal, setModal] = useState(null);
  const [modalConfirm, setModalConfirm] = useState(null);

  // QUERIES
  const { isLoading: isGettingTotal } = useGetTranslationsTotalQuery({ where }, { refetchOnMountOrArgChange: true });
  const { isLoading: isGetting } = useGetTranslationsQuery({ take, skip, where, orderBy }, { refetchOnMountOrArgChange: true });

  // MUTATIONS
  const [createTranslation, { isLoading: isCreating }] = useCreateTranslationMutation();
  const [updateTranslation, { isLoading: isUpdating }] = useUpdateTranslationMutation();
  const [deleteTranslation, { isLoading: isDeleting }] = useDeleteTranslationMutation();

  // VARS
  const isLoading = isGetting || isGettingTotal || isCreating || isUpdating || isDeleting;
  const headers = languages?.length ? [{ name: "Label", slug: "key", search: true }, ...languages.map((e) => e.language), { name: "" }] : [];

  useEffect(() => {
    if (project?.id) dispatch(setWhere({ ...where, project: { id: project.id } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  // FUNCTION TO CREATE A NEW TRANSLATION
  const onCreate = () => {
    setModal({
      isOpen: true,
      project: project,
      languages: languages,
      onClose: () => setModal(null),
      onSubmit: ({ key, values }) => {
        createTranslation({ project: project?.id, key, values }).then((res) => {
          if (res.data) showServerSuccess(i18n("toast.translation.created"));
          else showServerError(res);
        });
        setModal(null);
      },
    });
  };
  // FUNCTION TO EDIT
  const onEdit = (item) => {
    setModal({
      translation: item,
      isOpen: true,
      project: project,
      languages: languages,
      buttonText: i18n("button.update"),
      onClose: () => setModal(null),
      onSubmit: ({ key, values }) => {
        updateTranslation({ id: item.id, key, values }).then((res) => {
          if (res.data) showServerSuccess(i18n("toast.translation.updated"));
          else showServerError(res);
        });
        setModal(null);
      },
    });
  };
  // FUNCTION TO DELETE
  const onDelete = (item) => {
    setModalConfirm({
      isOpen: true,
      type: "DELETE",
      mode: "BASIC",
      title: i18n("label.delete_translation"),
      text: i18n("label.delete_translation_text"),
      onConfirm: () => {
        deleteTranslation({ id: item.id }).then((res) => {
          if (res.data) showServerSuccess(i18n("toast.translation.delete"));
          else showServerError(res);
          setModalConfirm(null);
        });
      },
      onClose: () => {
        setModalConfirm(null);
      },
    });
  };

  // FUNCTION TO FILTER BY NAME
  const onFilterSearch = (some) => {
    if (typeof setWhere !== "undefined") {
      if (!some) {
        dispatch(setWhere({ project: { id: project?.id } }));
        return;
      }

      const text = some?.OR?.length ? some.OR[0].key : undefined;
      dispatch(setWhere({ ...where, OR: [{ key: text }, { values: { some: { value: text } } }] }));
    }
  };

  return (
    <div className="custom-container">
      <div className="flex items-center justify-between pt-32 lg:pt-16 pb-10">
        <span className="flex items-center">
          <h1 className="text_3xl">{i18n("label.translations")}</h1>
          <Loading active={isLoading} className="ml-3" />
        </span>
        <Permission project dev min="ADMINISTRATOR">
          <button type="button" className="btn-primary-outline" onClick={onCreate}>
            <IconPlus />
            {i18n("button.new_translation")}
          </button>
        </Permission>
      </div>
      <div>
        <div className="overflow-x-auto overflow-y-visible relative h-full pb-16">
          <table className="listTable tableSimple w-full text-sm text-left text-gray-500">
            <Thead headers={headers} setFilter={onFilterSearch} />
            {!!translations?.length && (
              <tbody className="relative">
                {translations.map((translation, key) => (
                  <ItemTranslation key={String(key)} translation={translation} languages={languages} onEdit={() => onEdit(translation)} onDelete={() => onDelete(translation)} />
                ))}
              </tbody>
            )}
          </table>
        </div>
        {!!translations?.length && total > translations?.length && (
          <div className="flex justify-center mt-8">
            <button type="button" className="btn-primary-outline" onClick={() => dispatch(setPage(page + 1))}>
              {i18n("button.load_more")}
            </button>
          </div>
        )}
      </div>

      <ModalTranslation {...modal} isLoading={isLoading} />
      <ModalConfirm {...modalConfirm} isLoading={isLoading} />
    </div>
  );
}
