import { PencilIcon, PlusIcon } from "@heroicons/react/24/outline";
import Icon from "app/components/atoms/icons/icon";
import { Content } from "app/components/atoms/modal";
import Accordion from "app/components/molecules/accordion";
import Dropdown from "app/components/molecules/dropdown";
import ModalPost from "app/components/molecules/modals/modalPost";
import Permission from "app/components/molecules/permission";
import Form from "app/components/organisms/form";
import { i18n } from "app/i18n";
import { useUpdatePostInfoMutation } from "app/stores/post";
import { setUnsavedChanges, useCreatePostTranslationMutation } from "app/stores/project";
import { canParseJSON, cleanStructure, getTranslation } from "app/utils/content";
import { getFlag } from "app/utils/languages";
import { hasPermission } from "app/utils/roles";
import { showServerError, showServerSuccess } from "app/utils/server";
import { validation } from "app/utils/validators";
import { isArray, isObject } from "app/utils/validators/dataValidator";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { components, tabs } from "./constants";

export default function PostEditionProperties(props) {
  const { loading } = props;

  // ROUTE
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { key, project: idProject, lang } = useParams();

  // STORE
  const { user } = useSelector((state) => state.auth);
  const { post } = useSelector((store) => store.post);
  const { project, unsavedChanges, users, language, languages } = useSelector((state) => state.project);

  // STATES
  const [value, setValue] = useState(null);
  const [modal, setModal] = useState(null);
  const [structure, setStructure] = useState(null);
  const [invalidForm, setInvalidForm] = useState(false);
  const [sidebarStructure, setSidebarStructure] = useState(null);
  const [dataChanged, setDataChanged] = useState(false);

  // REQUEST
  const [updatePost, { isLoading: isUpdating }] = useUpdatePostInfoMutation();
  const [createPostTranslation, { isLoading: isCreating }] = useCreatePostTranslationMutation();

  // VARS
  const isLoading = loading || isUpdating || isCreating;
  const content = post?.translations?.find((item) => item.language.slug === lang);

  useEffect(() => {
    if (post) buildForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post]);

  useEffect(() => {
    setInvalidForm(false);
    setDataChanged(isDataDifferent());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (dataChanged !== unsavedChanges) dispatch(setUnsavedChanges(dataChanged));
    return () => dispatch(setUnsavedChanges(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataChanged]);

  function buildForm() {
    if (!post) return;

    // PROPERTIES
    setStructure(post?.postType?.structure?.map((input) => ({ ...input, disabled: input.blocked })));

    // STRUCTURE
    const tempStructure = getStructure();
    setSidebarStructure([...tempStructure]);

    // VALUE
    const temp = getValue();
    setValue({ ...temp });
  }
  function getStructure() {
    let temp = cleanStructure(components);

    // USERS
    if (users?.length) {
      const index = temp.findIndex((item) => item.key === "global");
      if (index !== -1) {
        const index1 = temp[index].structure.findIndex((item) => item.key === "author");
        if (index1 !== -1) {
          temp[index].structure[index1].options = users
            .filter((e) => (hasPermission(user, ["DEVELOPER"]) ? true : e.projects.find((p) => p.project.id === project.id)))
            .map((user) => ({ value: user.id, label: user.name }));
          if (content?.author) {
            const indexAuthor = temp[index].structure[index1].options.findIndex((e) => e.value === content.author.id);
            if (indexAuthor === -1) temp[index].structure[index1].options.push({ value: content.author.id, label: content.author.name });
          }
        }
      }
    }

    // CATEGORIES
    if (post?.postType?.categories?.length) {
      const index = temp.findIndex((item) => item.key === "categories");
      if (index !== -1) {
        const children = [];
        for (let i = 0; i < post.postType.categories.length; i++) {
          children.push({
            type: "Select",
            creatable: true,
            searchable: true,
            clearable: true,
            key: post.postType.categories[i].id,
            title: post.postType.categories[i].title,
            multiple: post.postType.categories[i].multi,
            isRequired: post.postType.categories[i].required,
            options: post.postType.categories[i].options.map((item) => ({ value: item.id, label: getTranslation(item.translations, language?.slug) })),
            rules: post.postType.categories[i].required
              ? [
                  {
                    method: (v) => {
                      console.log("V", v);
                      if (post.postType.categories[i].multi) return isArray(v, true);
                      else return isObject(v, true);
                    },
                    validWhen: true,
                    message: "Required Field",
                  },
                ]
              : [],
          });
        }
        temp[index].structure = [{ type: "Group", key: "categories", children }];
      }
    } else {
      const index = temp.findIndex((item) => item.key === "categories");
      if (index !== -1) temp[index].structure = [];
    }

    return temp;
  }
  function getValue() {
    const temp = {};
    if (content) {
      temp.author = undefined;
      temp.categories = undefined;
      temp.properties = undefined;
      temp.excerpt = content?.excerpt || undefined;
      temp.thumbnail = content?.thumbnail || undefined;

      // AUTHOR
      if (content.author) {
        temp.author = { value: content.author.id, label: content.author.name };
      }
      // PROPERTIES
      if (content?.properties?.length) {
        for (let i = 0; i < content.properties.length; i++) {
          if (canParseJSON(content.properties[i].value)) {
            temp[content.properties[i].key] = JSON.parse(content.properties[i].value);
          } else {
            temp[content.properties[i].key] = content.properties[i].value;
          }
        }
      }
      // CATEGORIES
      if (content?.categories?.length) {
        temp.categories = {};
        let types = [];
        content.categories.forEach((item) => {
          const index = types.findIndex((e) => e.type === item.categoryType.id);
          if (index !== -1) {
            if (item.categoryType.multi) types[index].value = [...types[index].value, { value: item.id, label: getTranslation(item.translations, language?.slug) }];
            else types[index].value = { value: item.id, label: getTranslation(item.translations, language?.slug) };
          } else {
            types.push({
              type: item.categoryType.id,
              value: item.categoryType.multi
                ? [{ value: item.id, label: getTranslation(item.translations, language?.slug) }]
                : { value: item.id, label: getTranslation(item.translations, language?.slug) },
            });
          }
        });
        for (let i = 0; i < types.length; i++) {
          temp.categories[types[i].type] = types[i].value;
        }
      }
    }
    return temp;
  }
  function isDataDifferent() {
    if (isLoading) return false;
    return JSON.stringify(getValue()) !== JSON.stringify(value);
  }
  function cleanChanges() {
    const values = getValue();
    setValue(values);
  }
  function onSubmit() {
    if (!dataChanged) return false;

    const globalStructure = [...structure, ...sidebarStructure];

    validation(globalStructure, value, (structure, validation) => {
      const main = structure.filter((e) => !e.structure?.length);
      const sidebar = structure.filter((e) => !!e.structure?.length);

      setStructure([...main]);
      setSidebarStructure([...sidebar]);

      setInvalidForm(!validation.isValid);

      if (validation.isValid) {
        const { author, categories, excerpt, thumbnail, properties, ...rest } = value;
        const data = {
          id: content.id,
          author: author?.value,
          excerpt,
          categories,
          language: lang,
          properties: rest,
          thumbnail: thumbnail?.id ? { connect: { id: thumbnail.id } } : content?.thumbnail ? { disconnect: true } : undefined,
        };

        updatePost(data).then((res) => {
          if (res && res.data) showServerSuccess(i18n("toast.success_changes_update"));
          else showServerError(res);
        });
      }
    });
  }
  function onCreateTranslation(language) {
    const availableTranslations = languages
      .filter((e) => post.translations.find((content) => content.language.slug === e.language.slug))
      .map((e) => {
        const title = post.translations.find((content) => content.language.slug === e.language.slug).title;
        return { value: e.language.id, label: `<span className="mr-1">${getFlag(e.language.slug)}</span> ${title}` };
      });

    setModal({
      post: null,
      isOpen: true,
      templates: null,
      language: language,
      duplicate: true,
      availableTranslations,
      type: post.postType.key,
      onSubmit: ({ slug, title, copy, language: origin, translate }) => {
        createPostTranslation({ post: post.id, language: language.id, title, slug, copy, origin: origin?.value, translate }).then((res) => {
          if (!res.error && res.data) {
            navigate(`/project/${idProject}/posts/${key}/${res.data.post.id}/${res.data.language.slug}`);
            showServerSuccess(i18n("toast.success_create"));
          } else {
            showServerError(res);
          }
          setModal(null);
        });
      },
      onClose: () => setModal(null),
    });
  }

  return (
    <>
      <div className="flex flex-col flex-1 h-full overflow-hidden rounded-t-md">
        <div className="bg-white w-full border-b h-12" />
        <div className="bg-white flex-1 flex flex-col w-full rounded-b-md overflow-hidden">
          {/* EDITOR */}
          <div className="flex-1 overflow-auto pb-10">
            <Content>
              <Form structure={structure} onChange={setValue} value={value} />
            </Content>
          </div>

          {/* BUTTONS */}
          <div className="border-t py-4 px-6 flex justify-between">
            <Dropdown position="top-left" options={[{ title: i18n("button.clean_changes"), onClick: cleanChanges, icon: "rollback", delete: true }]} />
            <div className="flex">
              {invalidForm && (
                <>
                  <div className="flex items-center mr-2 rounded-md border border-transparent bg-red-100 text-gray-600 h-full py-1 pl-3 pr-6">
                    <div className="h-6 w-6 flex-shrink-0 rounded-full items-center justify-center bg-red-200 text-red-600 flex mr-2">
                      <Icon name="alert" />
                    </div>
                    <span className="text-xs inline-block leading-tight text-red-600">Verifique o formulário</span>
                  </div>
                </>
              )}
              <Permission project dev min="EDITOR">
                <button
                  type="button"
                  disabled={!dataChanged || isLoading}
                  onClick={onSubmit}
                  className="flex items-center relative justify-center rounded-md border border-transparent bg-blue-800 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none  disabled:bg-blue-200 disabled:cursor-not-allowed"
                >
                  {i18n("button.publish")}
                </button>
              </Permission>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col h-full">
        <div className="bg-white min-w-[336px] w-[336px] rounded-t-md border-b h-12" />
        <div className="bg-white flex flex-col justify-between rounded-b-xl xl:rounded-b-md rounded-t-xl xl:rounded-t-none w-full md:min-w-[336px] xl:w-[336px] shadow-lg max-h-full overflow-hidden">
          <div className="overflow-auto max-h-full">
            <div className="relative z-10">
              <div className="flex-1 overflow-auto max-h-full">
                <div className="relative">
                  <Accordion title={i18n("label.translations")} disabled defaultOpen={true}>
                    {!!languages?.length && (
                      <div>
                        <ul className="px-5 py-3">
                          {languages.map(({ language }, index) => {
                            const exists = post?.translations?.length ? post.translations.find((e) => e.language.slug === language.slug) : null;
                            return (
                              <li key={index} className="flex items-center justify-between py-3 border-b last:border-b-0">
                                {exists ? (
                                  <>
                                    {content.id === exists.id ? (
                                      <>
                                        {/* CURRENT LANGUAGE */}
                                        <span className="text-sm text-slate-900">
                                          <span className="mr-1">{getFlag(language.slug)}</span> {exists.title}
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <Link to={`/project/${idProject}/posts/${key}/${post.id}/${language.slug}`}>
                                          <span className="text-sm">
                                            <span className="mr-1">{getFlag(language.slug)}</span> {exists.title}
                                          </span>
                                        </Link>
                                        <Link to={`/project/${idProject}/posts/${key}/${post.id}/${language.slug}`}>
                                          <PencilIcon className="w-5" />
                                        </Link>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <span className="text-sm text-gray-400">
                                      <span className="mr-1">{getFlag(language.slug)}</span> {language.name}
                                    </span>
                                    <Permission project dev min="MANAGER">
                                      <button type="button" onClick={() => onCreateTranslation(language)}>
                                        <PlusIcon className="w-5" />
                                      </button>
                                    </Permission>
                                  </>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </Accordion>
                  {tabs.map((item, index) => {
                    const inputs = sidebarStructure?.find((e) => e.key === item.key);
                    const hasError = inputs?.error;
                    if (!inputs || !inputs.structure || !inputs.structure.length) {
                      return null;
                    }
                    return (
                      <Accordion defaultOpen={item.key === "categories"} error={hasError} key={item.key} title={item.title} className={`relative z-${(tabs.length - index) * 10}`}>
                        {isUpdating ? (
                          <div role="status" className="max-w-sm animate-pulse pt-7 pb-5">
                            <div className="h-2.5 bg-slate-300 rounded-full w-48 mb-4"></div>
                            <div className="h-2 bg-slate-300 rounded-full max-w-[360px] mb-2.5"></div>
                            <div className="h-2 bg-slate-300 rounded-full mb-2.5"></div>
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <div className={!index ? "px-5 py-3" : ""}>
                            <Form disabled={inputs.disabled} structure={inputs.structure} onChange={setValue} mode="sidebar" value={value} />
                          </div>
                        )}
                      </Accordion>
                    );
                  })}
                  <div className="h-12" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalPost {...modal} isLoading={isLoading} />
    </>
  );
}
