import Permission from "app/components/molecules/permission";
import ListUsers from "app/components/organisms/lists/listUsers";

export default function Users() {
  return (
    <Permission external redirect="/dashboard">
      <div>
        <ListUsers />
      </div>
    </Permission>
  );
}
